

































































































































































































































































































































































































































































































































































































































































































































































































































































































.el-upload-list__item {
  /deep/img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto !important;
    height: auto !important;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    z-index: 1;
  }
}
.h3 {
  padding: 0.75rem 0;
}
